<template>
    <div id="users-list">
        <v-container fluid fill-height>
            <v-layout justify-center align-center>
                <v-card width="100%" outlined elevation-0>
                    <v-card-title>
                        {{$t('risk.logs')}}
                        <v-spacer></v-spacer>
                        <div class="search-container mt-8 col-md-1">
                            <v-dialog ref="dialog" v-model="dialogFromTime" width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field dense v-model="filterFromTime" :label="$t('generic.from')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                                </template>
                                <v-date-picker v-model="filterFromTime"  scrollable @input="dialogFromTime = false"></v-date-picker>
                            </v-dialog>
                        </div>
                        <div class="search-container mt-8 col-md-1">
                            <v-dialog ref="dialog" v-model="dialogToTime" width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field dense v-model="filterToTime" :label="$t('generic.to')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                                </template>
                                <v-date-picker v-model="filterToTime" scrollable @input="dialogToTime = false"></v-date-picker>
                            </v-dialog>
                        </div>
                        <div class="statusUserSelectorContainer col-md-1" v-if="getPlatformId === 0">
                            <v-select
                                    label="Platform"
                                    density="compact"
                                    :items="getSkins"
                                    v-model="platformSelected"
                                    item-text="name"
                                    item-value="platform_id"
                            >
                            </v-select>
                        </div>
                        <div class="statusUserSelectorContainer col-md-1">
                            <v-select
                                    label="Status"
                                    density="compact"
                                    :items="typeStatus"
                                    v-model="typeStatusSelected"
                            >
                            </v-select>
                        </div>
                        <div class="statusUserSelectorContainer col-md-1">
                            <v-select
                                    label="Result"
                                    density="compact"
                                    :items="typeResults"
                                    v-model="typeResultsSelected"
                            >
                            </v-select>
                        </div>
                        <div class="search-container col-md-1">
                            <v-text-field  append-icon="search"  v-model="codeSelected"
                                          :label="this.$t('bets.code')"></v-text-field>
                        </div>
                        <div class="search-container col-md-2">
                            <v-text-field  append-icon="search"  v-model="betCodeSelected"
                                          label="Bet Code"></v-text-field>
                        </div>
                        <div class="search-container col-md-2">
                            <v-text-field  append-icon="search"  v-model="idEvent"
                                          label="Id Event"></v-text-field>
                        </div>
                        <div class="search-container col-md-1">
                            <v-text-field v-model="searchByLogin" append-icon="search"
                                          :label="this.$t('searchByLogin')"></v-text-field>
                        </div>
                        <div class="search-container col-md-1">
                            <v-btn depressed color="secondary" @click="getLogs('yes')" @keyup.enter="getLogs('yes')" class="mr-5">{{ $t('generic.search') }}</v-btn>
                        </div>
                    </v-card-title>

                        <v-container fluid>
                            <v-row dense>
                                <v-col cols="3" elevation-0 class="mt-1">
                                    <apexchart v-if="loaded_chart && !loader" type="bar" height="350" :options="chartOptionsSeries" :series="[{name:'Bets',data:[bets_total]},{name:'Accepted',data:[accepted_total]}]"></apexchart>
                                    <v-progress-circular v-if="loader" indeterminate color="primary" class="loader-center"></v-progress-circular>
                                </v-col>
                                <!--                                        <v-col cols="3" outlined elevation-0 class="mt-1">-->
                                <!--                                            <apexchart v-if="loaded_chart" type="pie" height="350" :options="chartOptions" :series="[win_percentage, lose_percentage]"></apexchart>-->
                                <!--                                        </v-col>-->
                                <v-col cols="6" elevation-0 class="mt-1">
                                    <!--                                            <apexchart v-if="loaded_chart_ai" type="bar" height="350" :options="chartOptionsSeriesAi" :series="[{name:'Bets',data:[bets_total_ai]},{name:'Accepted',data:[accepted_total_ai]},{name:'Rejected',data:[rejected_total_ai]}]"></apexchart>-->
                                    <apexchart v-if="loaded_chart_reject  && !loader" type="line" height="350" :options="chartRejected" :series="[{name: 'Rejected', data: data_rejects_series}]"></apexchart>
                                    <v-progress-circular v-if="loader" indeterminate color="primary" class="loader-center"></v-progress-circular>

                                </v-col>
                                <v-col cols="3" outlined elevation-0 class="mt-1">
                                    <apexchart v-if="loaded_chart_ai  && !loader_ai" type="pie" height="350" :options="chartOptionsAi" :series="[win_percentage_ai, lose_percentage_ai]"></apexchart>
                                    <v-progress-circular v-if="loader_ai"  indeterminate color="primary" class="loader-center"></v-progress-circular>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col v-if="amount !== 0 && !loader" cols="12" md="2" sm="6" xs="12">
                                    <v-alert
                                            color="#993f00"
                                            dark
                                            icon="mdi-currency-usd"
                                            border="left"
                                            prominent
                                    >
                                        <div class="title-alert-my-bets">Amount</div>
                                        <div class="description-alert-my-bets">{{this.currency}} {{this.amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}}</div>
                                    </v-alert>
                                </v-col>
                                <v-col v-if="win_amount !== 0 && !loader" cols="12" md="2" sm="6" xs="12">
                                    <v-alert
                                            color="#812b25"
                                            dark
                                            icon="mdi-currency-usd"
                                            border="left"
                                            prominent
                                    >
                                        <div class="title-alert-my-bets">Win</div>
                                        <div class="description-alert-my-bets">{{this.currency}} {{this.win_amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}}</div>
                                    </v-alert>
                                </v-col>
                                <v-col v-if="win_amount !== 0 && amount !== 0 && !loader" cols="12" md="2" sm="6" xs="12">
                                    <v-alert
                                            color="#125291"
                                            dark
                                            icon="mdi-percent"
                                            border="left"
                                            prominent
                                    >
                                        <div class="title-alert-my-bets">Payout</div>
                                        <div class="description-alert-my-bets">{{((this.win_amount / this.amount)*100).toFixed()}} %</div>
                                    </v-alert>
                                </v-col>
                                <v-col v-if="net !== 0 && !loader" cols="12" md="2" sm="6" xs="12">
                                    <v-alert
                                            color="#245e27"
                                            dark
                                            icon="mdi-currency-usd"
                                            border="left"
                                            prominent
                                    >
                                        <div class="title-alert-my-bets">Net</div>
                                        <div class="description-alert-my-bets">{{this.currency}} {{this.net.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}}</div>
                                    </v-alert>
                                </v-col>
                                <v-col v-if="net !== 0 && amount !== 0 && !loader" cols="12" md="2" sm="6" xs="12">
                                    <v-alert
                                            color="#915f15"
                                            dark
                                            icon="mdi-percent"
                                            border="left"
                                            prominent
                                    >
                                        <div class="title-alert-my-bets">GGR</div>
                                        <div class="description-alert-my-bets">{{((this.net / this.amount) * 100).toFixed(0)}} %</div>
                                    </v-alert>
                                </v-col>
                                <v-col v-if="lose_percentage_ai !== 0 && !loader_ai" cols="12" md="2" sm="6" xs="12">
                                    <v-alert
                                            color="#2A3B4D"
                                            dark
                                            icon="mdi-percent"
                                            border="left"
                                            prominent
                                    >
                                        <div class="title-alert-my-bets">Accuracy</div>
                                        <div class="description-alert-my-bets">{{this.lose_percentage_ai.toString()}} %</div>
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>

                    <v-data-table :headers="headers" :footer-props="footerTable" item-key="_id"
                                  :items="tableItems" search="" disable-pagination hide-default-footer>

                        <template v-slot:body="{ items }">
                            <tbody>
                            <tr v-for="item in items">
                                <td>{{ convertDate(item.docs.created_at) }}</td>
                                <td>{{ item.docs.code }}</td>
<!--                                <td>{{ item.docs.betCode }}</td>-->
                                <td >{{ item.login }}</td>
                                <td style="text-align: right">{{ item.amount_real }} {{ item.currencySymbol }}</td>
                                <td style="text-align: right">{{ item.docs.action }}</td>
                                <td style="text-align: right">{{ item.result }}</td>
                                <td style="text-align: right">
                                    <span v-if="(item.result === 'lose' && item.docs.action === 'reject') || (item.result === 'win' && item.docs.action === 'accept') " style="color: red">{{ item.winning }} {{ item.currencySymbol }}</span>
                                    <span v-else-if="(item.result === 'lose' && item.docs.action === 'accept') || (item.result === 'win' && item.docs.action === 'reject')"  style="color: green">{{ item.winning }} {{ item.currencySymbol }}</span>
                                    <span v-else>{{ item.winning }} {{ item.currencySymbol }}</span>
                                </td>
                                <td><v-btn icon @click="openMessageDialog(item.docs._id,item.docs.code)">
                                    <v-icon style="color: #615c5c">mdi-information</v-icon>
                                </v-btn></td>
                              <td>
                                <v-icon v-if="(item.response_ai && item.response_ai === 'accept' && item.result === 'win') || (item.response_ai && item.response_ai === 'reject' && item.result === 'lose')" style="color:red">mdi-checkbox-blank-circle</v-icon>
                                <v-icon v-if="item.response_ai && item.response_ai === 'accept' && item.result === 'lose' || (item.response_ai && item.response_ai === 'reject' && item.result === 'win')" style="color:green">mdi-checkbox-blank-circle</v-icon>
                              </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <div class="text-center pt-3 pb-3">
                        <v-pagination
                                v-model="pagination.page"
                                :current-page = "pagination.page"
                                :length="+(pagination.counts / pagination.itemsPerPage).toFixed(0)"
                                :total-visible="10"
                                @input="getNewPage(pagination.page)"
                        ></v-pagination>
                    </div>
                </v-card>
            </v-layout>
        </v-container>
<!--        Modale Dettagli-->
        <v-container>
            <v-dialog v-model="dialogNote" scrollable content-class="vdialogLogs"  id="modalDetailBetlog" class="modalDetailBet" transition="dialog-bottom-transition" :max-width="new_value && old_value ? '70%' : (details.predict && details.percentage) ? '55%' : '40%' ">
                <v-card>
                    <v-toolbar color="primary" dark class="text-capitalize" dense>
                        <span  v-if="old_value && new_value">{{ $t('bets.bet_detail_repurposed') }}</span>
                        <span  v-else >{{ $t('bets.bet_detail') }}</span>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click="closeDetail">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-container fluid >
                        <v-layout row>
                            <v-flex md12 class="content-bet" v-if="!new_value" >
                                <v-col cols="12">
                                    <v-card outlined elevation-0>
                                        <v-container fluid>

                                            <v-row>
                                                <v-col md="4" class="text-left">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.date')" :value=" (getTimezone != '') ? new Date(details.created_at).toLocaleString(undefined,{timeZone: getTimezone})+'*' : new Date(details.created_at).toLocaleString()" dense outlined readonly ></v-text-field>
                                                </v-col>
                                                <v-col md="4" class="text-center">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.code')" :value="details.code" dense outlined readonly ></v-text-field>
                                                </v-col>
                                                <v-col md="2" class="text-center">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.betCode')" :value="details.betCode ? details.betCode : ' ' " dense outlined readonly ></v-text-field>
                                                </v-col>
                                                <v-col md="2" class="text-center">
                                                    <div v-if="betContext" class="context-bet" >
                                                        <span>{{betContext}}</span>
                                                    </div>
                                                </v-col>

                                            </v-row>

                                            <v-row>
                                                <v-col md="3" class="pt-0">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.amount')" :value="amountReal" dense outlined readonly></v-text-field>
                                                </v-col>
                                                <v-col md="3" class="pt-0">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.result')" :value="betResult" dense outlined readonly></v-text-field>
                                                </v-col>
                                                <v-col md="3"  v-if="betDetail && betDetail.type != null" class="pt-0">
                                                    <v-text-field v-if="betDetail.type === 'single'"
                                                                  class="text-capitalize" style="font-weight: bolder" :label="$t('bets.odds')"
                                                                  :value="betDetail._multiple_detail.odds_total" dense
                                                                  outlined readonly></v-text-field>
                                                    <v-text-field v-if="betDetail.type === 'multiple'"
                                                                  class="text-capitalize" style="font-weight: bolder" :label="$t('bets.odds')"
                                                                  :value="betDetail._multiple_detail.odds_total" dense
                                                                  outlined readonly></v-text-field>
                                                    <v-text-field v-if="betDetail.type === 'integral'"
                                                                  class="text-capitalize" style="font-weight: bolder" :label="$t('bets.odds')"
                                                                  :value="betDetail._integral_detail.odds_total_max"
                                                                  dense outlined readonly></v-text-field>
                                                    <v-text-field v-if="betDetail.type === 'system'"
                                                                  class="text-capitalize" style="font-weight: bolder" :label="$t('bets.odds')"
                                                                  value="N.D." dense outlined readonly></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col md="3" class="pt-0">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.user')" :value="details.login" dense outlined readonly></v-text-field>
                                                </v-col>
                                                <v-col md="3" class="pt-0">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.net')" :value="old_value.agencyLogin" dense outlined readonly></v-text-field>
                                                </v-col>
                                                <v-col md="3" class="pt-0  text-center">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.user')" :value="getPlatformName(details.platform_id)" dense outlined readonly></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-2" v-if="old_value.reject_msg">
                                                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                                    <v-flex class="red block text-center msg-error"
                                                            key="riskErrorMessage_">{{ old_value.reject_msg }}
                                                    </v-flex>
                                                </v-col>
                                            </v-row>
                                            <v-row class="bet-info">
                                                <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 ptb"
                                                       v-for="(odd, index) in old_value.odds" :key="index">
                                                      <span class="odd-event-description">
                                                        <h5>
                                                          {{ odd.event_description }}
                                                          <small v-if="odd && odd.type != null && odd.type == 'live'"
                                                                 style="font-size: 12px; color: #000;font-weight: 600;">
                                                            (<v-icon small>mdi-target</v-icon>{{(odd.result_live) ? odd.result_live : ''}} <v-icon
                                                                  small>mdi-clock-time-eight-outline</v-icon>{{ (odd.time_live) ? odd.time_live+"'" : ''}})
                                                        </small>
                                                        </h5>
                                                      </span>
                                                    <v-row class="bet-info">
                                                        <v-col cols="12" lg="4" md="4" sm="4" class="pt-4 pt-0 pb-0">
                                                            <v-text-field
                                                                    :value="(odd.outcome_alias != undefined && odd.outcome_alias != '') ? odd.outcome_alias : odd.outcome_name"
                                                                    class="disabled-dark" dense outlined
                                                                    style="font-weight: bolder"
                                                                    readonly></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" lg="4" md="4" sm="4" class="pt-4 pt-0 pb-0">
                                                            <v-text-field :value="getMarketName(odd)"
                                                                          class="disabled-dark" dense outlined
                                                                          style="font-weight: bolder"
                                                                          readonly></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" lg="4" md="4" sm="4" class="pt-4 pt-0 pb-0">
                                                            <v-text-field :ref="details.code+'_odds'"
                                                                          :value="odd.outcome_odd_value"
                                                                          style="font-weight: bolder"
                                                                          @input="event => odd.outcome_odd_value = event"
                                                                          type="number" min="0" step="0.01" dense
                                                                          outlined></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col v-if="details.predict && details.percentage && details.predict === 'accept'" cols="12" md="12" sm="12" xs="12">
                                                    <v-alert
                                                            color="#245e27"
                                                            dark
                                                            icon="mdi-percent"
                                                            border="left"
                                                            prominent
                                                    >
                                                        <div class="title-alert-my-bets text-capitalize ">Predict: {{details.predict}}</div>
                                                        <div class="description-alert-my-bets">Percentage: {{(details.percentage.toFixed(3)).toString()}} %</div>
                                                    </v-alert>
                                                </v-col>
                                                <v-col v-if="details.predict && details.percentage && details.predict === 'reject'" cols="12" md="12" sm="12" xs="12">
                                                    <v-alert
                                                            color="#812b25"
                                                            dark
                                                            icon="mdi-percent"
                                                            border="left"
                                                            prominent
                                                    >
                                                        <div class="title-alert-my-bets text-capitalize ">Predict : {{details.predict}}</div>
                                                        <div class="description-alert-my-bets">Percentage : {{(details.percentage.toFixed(3)).toString()}} %</div>
                                                    </v-alert>
                                                </v-col>
                                              <v-col v-if="details.statistics" cols="3" md="3" sm="6" xs="12">
                                                <v-alert
                                                    color="#125291"
                                                    dark
                                                    icon="mdi-percent"
                                                    border="left"
                                                    prominent
                                                >
                                                  <div class="title-alert-my-bets text-capitalize ">Betting History</div>
                                                  <div class="description-alert-my-bets">{{(details.statistics.betting_history.toFixed(3)).toString()}} %</div>
                                                </v-alert>
                                              </v-col>
                                              <v-col v-if="details.statistics" cols="3" md="3" sm="6" xs="12">
                                                <v-alert
                                                    color="#2a3b4d"
                                                    dark
                                                    icon="mdi-percent"
                                                    border="left"
                                                    prominent
                                                >
                                                  <div class="title-alert-my-bets text-capitalize ">Market Move</div>
                                                  <div class="description-alert-my-bets">{{(details.statistics.market_move.toFixed(3)).toString()}} %</div>
                                                </v-alert>
                                              </v-col>
                                              <v-col v-if="details.statistics" cols="3" md="3" sm="6" xs="12">
                                                <v-alert
                                                    color="#812b25"
                                                    dark
                                                    icon="mdi-percent"
                                                    border="left"
                                                    prominent
                                                >
                                                  <div class="title-alert-my-bets text-capitalize ">NVR</div>
                                                  <div class="description-alert-my-bets">{{(details.statistics.nvr.toFixed(3)).toString()}} %</div>
                                                </v-alert>
                                              </v-col>
                                              <v-col v-if="details.statistics" cols="3" md="3" sm="6" xs="12">
                                                <v-alert
                                                    color="#915f15"
                                                    dark
                                                    icon="mdi-percent"
                                                    border="left"
                                                    prominent
                                                >
                                                  <div class="title-alert-my-bets text-capitalize ">ODDS</div>
                                                  <div class="description-alert-my-bets">{{(details.statistics.odds.toFixed(3)).toString()}} %</div>
                                                </v-alert>
                                              </v-col>
                                            </v-row>

                                        </v-container>
                                    </v-card>
                                </v-col>
                            </v-flex>








                            <v-flex md6 class="content-bet" v-else>
                                <v-col cols="12">
                                    <v-card outlined elevation-0>
                                        <v-container fluid>

                                            <v-row>
                                                <v-col md="4" class="text-left">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.date')" :value=" (getTimezone != '') ? new Date(details.created_at).toLocaleString(undefined,{timeZone: getTimezone})+'*' : new Date(details.created_at).toLocaleString()" dense outlined readonly ></v-text-field>
                                                </v-col>
                                                <v-col md="4" class="text-center">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.code')" :value="details.code" dense outlined readonly ></v-text-field>
                                                </v-col>
                                                <v-col md="2" class="text-center">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.betCode')" :value="details.betCode ? details.betCode : ' ' " dense outlined readonly ></v-text-field>
                                                </v-col>
                                                <v-col md="2" class="text-center">
                                                    <div v-if="betContext" class="context-bet">
                                                        <span>{{betContext}}</span>
                                                    </div>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col md="3" class="pt-0">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.amount')" :value="amountReal" dense outlined readonly></v-text-field>
                                                </v-col>
                                                <v-col md="3" class="pt-0">

                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.result')" :value="betResult" dense outlined readonly></v-text-field>
                                                </v-col>
                                                <v-col md="3" v-if="betDetail && betDetail.type != null" class="pt-0">
                                                    <v-text-field v-if="betDetail.type === 'single'" class="text-capitalize" style="font-weight: bolder" :label="$t('bets.odds')" :value="betDetail._multiple_detail.odds_total" dense outlined readonly ></v-text-field>
                                                    <v-text-field v-if="betDetail.type === 'multiple'" class="text-capitalize" style="font-weight: bolder" :label="$t('bets.odds')" :value="betDetail._multiple_detail.odds_total" dense outlined readonly ></v-text-field>
                                                    <v-text-field v-if="betDetail.type === 'integral'" class="text-capitalize" style="font-weight: bolder" :label="$t('bets.odds')" :value="betDetail._integral_detail.odds_total_max" dense outlined readonly ></v-text-field>
                                                    <v-text-field v-if="betDetail.type === 'system'" class="text-capitalize" style="font-weight: bolder" :label="$t('bets.odds')" value="N.D." dense outlined readonly ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col md="3" class="pt-0">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.user')" :value="details.login" dense outlined readonly></v-text-field>
                                                </v-col>
                                                <v-col md="3" class="pt-0">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.net')" :value="agencyLogin" dense outlined readonly></v-text-field>
                                                </v-col>
                                                <v-col md="3" class="pt-0  text-center">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.user')" :value="getPlatformName(details.platform_id)" dense outlined readonly></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-2" v-if="old_value.reject_msg">
                                                <v-col cols="12" md="12" sm="12"  class="pt-0 pb-0">
                                                    <v-flex class="red block text-center msg-error" key="riskErrorMessage_" >{{ old_value.reject_msg }}</v-flex>
                                                </v-col>
                                            </v-row>
                                            <v-row class="bet-info" >
                                                <v-col cols="12" lg="12" md="12" sm="12"  class="pt-0 ptb" v-for="(odd, index) in old_value.odds" :key="index">
                                                      <span class="odd-event-description">
                                                        <h5>
                                                          {{ odd.event_description }}
                                                          <small v-if="odd && odd.type != null && odd.type === 'live'" style="font-size: 12px; color: #000;font-weight: 600;">
                                                            (<v-icon small>mdi-target</v-icon>{{(odd.result_live) ? odd.result_live : ''}} <v-icon small>mdi-clock-time-eight-outline</v-icon>{{ (odd.time_live) ? odd.time_live+"'" : ''}})
                                                        </small>
                                                        </h5>
                                                      </span>
                                                    <v-row class="bet-info">
                                                        <v-col cols="12" lg="4" md="4" sm="4"  class="pt-4 pt-0 pb-0">
                                                            <v-text-field :value="(odd.outcome_alias != undefined && odd.outcome_alias != '') ? odd.outcome_alias : odd.outcome_name" class="disabled-dark" style="font-weight: bolder" dense outlined readonly></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" lg="4" md="4" sm="4"  class="pt-4 pt-0 pb-0">
                                                            <v-text-field :value="getMarketName(odd)"  class="disabled-dark" style="font-weight: bolder" dense outlined readonly ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" lg="4" md="4" sm="4"  class="pt-4 pt-0 pb-0">
                                                            <v-text-field :ref="details.code+'_odds'" style="font-weight: bolder" :value="odd.outcome_odd_value" @input="event => odd.outcome_odd_value = event" type="number" min="0" step="0.01" dense outlined readonly></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>

                                        </v-container>
                                    </v-card>
                                </v-col>
                            </v-flex>
                            <v-flex md6 class="content-bet" v-if="new_value">
                                <v-col cols="12">
                                    <v-card outlined elevation-0>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col md="4" class="text-left">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.date')" :value=" (getTimezone != '') ? new Date(details.created_at).toLocaleString(undefined,{timeZone: getTimezone})+'*' : new Date(details.created_at).toLocaleString()" dense outlined readonly ></v-text-field>
                                                </v-col>
                                                <v-col md="4" class="text-center">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.code')" :value="details.code" dense outlined readonly ></v-text-field>
                                                </v-col>
                                                <v-col md="2" class="text-center">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.betCode')" :value="details.betCode ? details.betCode : ' ' " dense outlined readonly ></v-text-field>
                                                </v-col>
                                                <v-col md="2" class="text-center">
                                                    <div v-if="betContext" class="context-bet">
                                                        <span>{{betContext}}</span>
                                                    </div>
                                                </v-col>

                                            </v-row>
                                            <v-row>
                                                <v-col md="3" class="pt-0">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.amount')" :value="new_value.amount" dense outlined readonly ></v-text-field>
                                                </v-col>
                                                <v-col md="3" class="pt-0">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.result')" :value="betResult"  dense outlined readonly></v-text-field>
                                                </v-col>
                                                <v-col md="3" v-if="betDetail && betDetail.type != null" class="pt-0">
                                                    <v-text-field v-if="betDetail.type === 'single'" class="text-capitalize" style="font-weight: bolder" :label="$t('bets.odds')" :value="betDetail._multiple_detail.odds_total" dense outlined readonly ></v-text-field>
                                                    <v-text-field v-if="betDetail.type === 'multiple'" class="text-capitalize" style="font-weight: bolder" :label="$t('bets.odds')" :value="betDetail._multiple_detail.odds_total" dense outlined readonly ></v-text-field>
                                                    <v-text-field v-if="betDetail.type === 'integral'" class="text-capitalize" style="font-weight: bolder" :label="$t('bets.odds')" :value="betDetail._integral_detail.odds_total_max" dense outlined readonly ></v-text-field>
                                                    <v-text-field v-if="betDetail.type === 'system'" class="text-capitalize" style="font-weight: bolder" :label="$t('bets.odds')" value="N.D." dense outlined readonly ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col md="3" class="pt-0">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.user')" :value="details.login" dense outlined readonly></v-text-field>
                                                </v-col>
                                                <v-col md="3" class="pt-0">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.net')" :value="agencyLogin" dense outlined readonly></v-text-field>
                                                </v-col>
                                                <v-col md="3" class="pt-0  text-center">
                                                    <v-text-field class="text-capitalize" style="font-weight: bolder" :label="$t('bets.user')" :value="getPlatformName(details.platform_id)" dense outlined readonly></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-2" v-if="new_value.reject_msg">
                                                <v-col cols="12" md="12" sm="12"  class="pt-0 pb-0">
                                                    <v-flex class="red block text-center msg-error" key="riskErrorMessage_" >{{ new_value.reject_msg }}</v-flex>
                                                </v-col>
                                            </v-row>
                                            <v-row class="bet-info" >
                                                <v-col cols="12" lg="12" md="12" sm="12"  class="pt-0 ptb" v-for="(odd, index) in new_value.odds" :key="index">
                                                      <span class="odd-event-description">
                                                        <h5>
                                                          {{ odd.event_description }}
                                                          <small v-if="odd && odd.type != null && odd.type == 'live'" style="font-size: 12px; color: #000;font-weight: 600;">
                                                            (<v-icon small>mdi-target</v-icon>{{(odd.result_live) ? odd.result_live : ''}} <v-icon small>mdi-clock-time-eight-outline</v-icon>{{ (odd.time_live) ? odd.time_live+"'" : ''}})
                                                        </small>
                                                        </h5>
                                                      </span>
                                                    <v-row class="bet-info">
                                                        <v-col cols="12" lg="4" md="4" sm="4"  class="pt-4 pt-0 pb-0">
                                                            <v-text-field :value="(odd.outcome_alias != undefined && odd.outcome_alias != '') ? odd.outcome_alias : odd.outcome_name" class="disabled-dark" style="font-weight: bolder" dense outlined readonly></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" lg="4" md="4" sm="4"  class="pt-4 pt-0 pb-0">
                                                            <v-text-field :value="getMarketName(odd)" class="disabled-dark" dense outlined readonly ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" lg="4" md="4" sm="4"  class="pt-4 pt-0 pb-0">
                                                            <v-text-field :ref="details.code+'_odds'" :value="odd.outcome_odd_value" @input="event => odd.outcome_odd_value = event" style="font-weight: bolder" type="number" min="0" step="0.01" dense outlined readonly ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card>
                                </v-col>
                            </v-flex>

                        </v-layout>
                    </v-container>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import BetDetail from "@/components/bets/betDetail";
    import NetworkDetail from "@/components/risks/networkDetail";
    import UserInfo from "@/components/risks/userInfo";
    import UserNote from "@/components/risks/userNote";
    import BetsList from "@/components/risks/betsList";
    import moment from "moment-timezone";
    import axios from "axios";
    import _ from "lodash";
    //import betDetail from "../../components/bets/betDetail";
    import { Line } from 'vue-chartjs'

    export default {
        name: "Logs",
        components: {NetworkDetail, BetsList, BetDetail, UserInfo, UserNote, LineChart:Line},
        data() {
            return {
                loaded_chart:false,
                loaded_chart_ai:false,
                loaded_chart_reject:false,
                loader:false,
                loader_ai:false,
                bets_total: 0,
                accepted_total: 0,
                rejected_total: 0,
                win: 0,
                lose: 0,
                win_percentage: 0,
                lose_percentage: 0,
                accept_percentage: 0,
                reject_percentage: 0,
                net: 0,
                net_ai: 0,
                bets_total_ai: 0,
                accepted_total_ai: 0,
                rejected_total_ai: 0,
                win_ai: 0,
                lose_ai: 0,
                win_amount: 0,
                win_amount_ai: 0,
                amount: 0,
                win_percentage_ai: 0,
                lose_percentage_ai: 0,
                accept_percentage_ai: 0,
                reject_percentage_ai: 0,
                skin:{
                    ai_safebet: false
                },
                rules: {
                    required: value => !!value || "Required."
                },
                icons: [
                    {'text': 'Black and white', value: "B/W"},
                    {'text': 'Color', value: "Color"}
                ],
                series_total: [],
                chartOptionsSeries: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    title: {
                        text: "Bet trends Staff"
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                    enabled: false
                    },
                    stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                    },
                    xaxis: {
                    categories: [''],
                    },
                    fill: {
                    opacity: 1
                    },
                },
                chartOptionsSeriesAi: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    title: {
                        text: "Bet trends Sirplay"
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                    enabled: false
                    },
                    stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                    },
                    xaxis: {
                    categories: [''],
                    },
                    fill: {
                    opacity: 1
                    },
                },
                chartOptions:{
                    colors: ['#14E66E', '#A6453D'],
                    chart: {
                        width: 380,
                        type: 'pie',
                    },
                    title: {
                        text: "Staff",
                    },
                    labels: ['Win', 'Lose'],
                    dataLabels: {
                        enabled: true
                        /*style: {
                            colors: ['#F44336', '#E91E63', '#9C27B0']
                        }*/
                    },
                    tooltip: {
                        enabled: false,
                    },
                    responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        },
                    }
                    }]
                },
                chartOptionsAi:{
                    colors: ['#914387', '#4A6640'],
                    chart: {
                        width: 380,
                        type: 'pie',
                    },
                    title: {
                        text: "Sirplay"
                    },
                    labels: ['Win', 'Lose'],
                    dataLabels: {
                        enabled: true,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                        width: 200
                        },
                        legend: {
                        position: 'bottom'
                        }
                    }
                    }]
                },
                chartRejected:{
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        text: 'Rejected',
                        align: 'left'
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: [],
                    }
                },

                urlRisk: 'https://risk.srp.tools/',
                urlBet: 'https://rockybet.srp.tools/',
                search: '',
                searchById: '',
                searchByLogin: '',
                platformSelected: 0,
                typeStatusSelected: '',
                typeResultsSelected: '',
                codeSelected: '',
                betCodeSelected: '',
                idEvent: '',
                modalCode: '',
                old_value: '',
                new_value: '',
                betContext: '',
                betResult: '',
                amountReal: '',
                agencyLogin: '',
                newCode: '',
                newBetCode: '',
                dialogFromTime: false,
                dialogToTime: false,
                showShortCode: false,
                filterFromTime: moment().subtract(1, 'w').format('YYYY-MM-DD'),
                filterToTime: moment().format('YYYY-MM-DD'),
                pagination: {
                    sortBy: 'created_at',
                    descending: true,
                    // itemsPerPage: '',
                    itemsPerPage: 10,
                    page:1,
                    counts:0
                },
                dialogNote: false,
                currency: '',
                tableItems: [],
                betDetail: [],
                details: [],
                data_rejects: [],
                data_rejects_categories: [],
                data_rejects_series: [],
                headers: [
                    // {text: this.$t('id'), value: 'user_id'},
                    {text: this.$t('date'), value: 'created_at'},
                    {text: this.$t('bets.code'), value: 'code'},
                    // {text: 'Bet Code', value: 'betCode'},
                    {text: this.$t('user.login'), value: 'login'},
                    {text: this.$t('bets.amount'), value: 'amount_real', align:"right"},
                    {text: this.$t('risk.typeOfAction'), value: 'action', align:"right"},
                    {text: this.$t('bets.result'), value: 'result', align:"right"},
                    {text: this.$t('bets.win')+'/'+this.$t('bets.potential')+' '+this.$t('bets.winning'), value: 'win', align:"right"},
                    {text: this.$t('generic.actions'), value: '_id'},
                    {text: 'Ai', value: 'response_ai'},
                ],
                footerTable: {
                    disableItemsPerPage: false,
                    itemsPerPageText: this.$t('datatable.itemsPerPageText'),
                    itemsPerPageAllText: this.$t('generic.all'),
                    'items-per-page-options':[10, 25, 50, 100, -1],
                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2}',
                },
                typeStatus: [
                    {text: this.$t('generic.all'), value: ''},
                    {text: this.$t('risk.accept'), value: 'accept'},
                    {text: this.$t('risk.check'), value: 'check'},
                    {text: this.$t('bets.review'), value: 'review'},
                    {text: this.$t('risk.reject'), value: 'reject'}
                ],
                typeResults: [
                    {text: this.$t('generic.all'), value: ''},
                    {text: this.$t('bets.pending'), value: 'pending'},
                    {text: this.$t('bets.lose'), value: 'lose'},
                    {text: this.$t('bets.win'), value: 'win'},
                    {text: this.$t('bets.void'), value: 'void'},
                    {text: this.$t('bets.cancelled'), value: 'cancelled'}
                ]
            }
        },
        async mounted() {
            // await this.getLogs();

            this.series_total = [10, 20, 30];
        },
        async beforeDestroy() {

        },
        watch: {
            getSkinFilters(newValue, oldValue){
                this.getPlatformsignUp(this.getPlatformId)
            }
        },
        computed: {
            ...mapGetters({
                'getPlatformId': 'rules/getPlatformId',
                'getTimezone':'bet/getTimezone',
                'getSkinFilters': 'skins/getSkinFilters',
                'getSkins': 'skins/getList'
            }),
        },
        methods: {
            ...mapActions({
                'setPreload': 'utils/setPreload',
                'getUsers': 'skins/getUsers',
                'blockUserAction': 'skins/blockUser',
                'unlockUserAction': 'skins/unlockUser',
                'fetchBetDetail': 'bet/fetchBetDetail',
                'getUrl': 'settings/getUrl',
            }),
            getPlatformsignUp(platform_id) {
                this.skin = _.find(this.getSkinFilters, {platform_id: platform_id})
            },
            getPlatformName(platform_id) {
                if(platform_id === 0){
                    return "Manager";
                }else{
                    let platform = _.find(this.getSkinFilters, {platform_id: platform_id})
                    if(platform){
                        return platform.name
                    }else{
                        return "Platform"
                    }

                }
            },

            getMarketName(odd){
                let text = odd.market_name;

                let spread = this.getSpread(odd.market_unique_id);

                if(spread != ''){
                    text = odd.market_name + ' (' + spread + ')';
                }

                return text;
            },

            getSpread(spec) {
                var result = ''

                if(spec.includes('#')){

                    var split = spec.split('#')
                    if(split[1].includes(':')){
                        result = '('+split[1]+')'
                    }else if(split[1].includes('(') && split[1].includes('.')){
                        result = ' ['+split[1]+'] '
                    }else if(split[1] != ''){
                        result = split[1]
                    }

                    if(_.size(split) > 2){
                        return result + ' ' + split[2]
                    }else {
                        return result
                    }

                }else {
                    return  result
                }
            },
            closeDetail() {
                this.dialogNote = false;
                this.details = [];
                this.modalCode = '';
            },
            convertDate(date){
                var d = new Date(date);
                var newDate = d.toLocaleString();
                return newDate;
            },
            oldValueDecode(ov){
                this.old_value = JSON.parse(ov);
            },
            newValueDecode(ov){
                this.new_value = JSON.parse(ov);
            },
            async getNewPage(page) {
                this.pagination.page = page
                await this.getLogs('no');
            },
            async getChart(){
                let platform;
                this.loader = true;
                if(this.getPlatformId === 0){
                    if (this.platformSelected > 0) {
                        platform = this.platformSelected;
                    }else{
                        platform = '';
                    }
                }else{
                    if (this.getPlatformId > 0) {
                        platform = this.getPlatformId;
                    }else{
                        platform = '';
                    }
                }

                let action = this.typeStatusSelected;
                let result = this.typeResultsSelected;
                let code = this.codeSelected;
                let betCode = this.betCodeSelected;
                let idEvent = this.idEvent;
                let searchById = this.searchById;
                let searchByLogin = this.searchByLogin;
                let fromTime = "";
                let toTime = "";

                if(this.filterFromTime){
                    fromTime = new Date(this.filterFromTime).getTime();
                }
                if(this.filterToTime) {
                    toTime = new Date(this.filterToTime).getTime() + 86399000;
                }

                let url = '' ;
                this.chartRejected.xaxis.categories = [];
                this.data_rejects_series = [];
                // if(this.skin.ai_safebet){
                //     this.chartOptionsSeries.title.text = "Limit Platform";
                //     url = this.urlRisk + 'logs/charts-platform';
                // }else{
                    this.chartOptionsSeries.title.text = "Limit Platform";
                    url = this.urlRisk + 'logs/charts';
                // }
                await axios.post(`${url}`, {
                    "platform_id" : platform,
                    "action" : action,
                    "result" : result,
                    "code" : code,
                    "betCode" : betCode,
                    "idEvent" : +idEvent,
                    "user_id":searchById, //chi accetta
                    "login":searchByLogin,
                    "fromTime":fromTime,
                    "toTime":toTime,
                    "page":this.pagination.page,
                    "perPage":this.pagination.itemsPerPage
                },{'headers': {'Authorization': `Bearer ${this.getToken}`}})
                    .then(async (res) => {
                        this.bets_total = res.data.data.total;
                        this.accepted_total = res.data.data.accepted;
                        this.rejected_total = res.data.data.rejected;
                        this.win = res.data.data.win;
                        this.win_amount = res.data.data.win_amount.toFixed(0);
                        this.amount = res.data.data.lose_amount.toFixed(0);
                        this.lose = res.data.data.lose;
                        this.win_percentage = res.data.data.win_percentage;
                        this.lose_percentage = res.data.data.lose_percentage;
                        this.accept_percentage = res.data.data.accept_percentage;
                        this.reject_percentage = res.data.data.reject_percentage;
                        this.net = (res.data.data.net!= null) ? res.data.data.net.toFixed(0) : 0;
                        this.data_rejects = res.data.data.data_rejects;
                        // data_rejects
                        for( let k of Object.keys(res.data.data.data_rejects)){
                            this.chartRejected.xaxis.categories.push(k)
                        }
                        for( let ks of Object.values(res.data.data.data_rejects)){
                            this.data_rejects_series.push(ks)
                        }

                        // console.log(this.data_rejects_categories)
                        this.loaded_chart = true;
                        this.loaded_chart_reject = true;
                        this.loader = false;

                    }).catch((e)=>{
                        console.log(e)
                    })

            },
            async getChartAi(){
                let platform;
                this.loader_ai = true;
                if(this.getPlatformId === 0){
                    if (this.platformSelected > 0) {
                        platform = this.platformSelected;
                    }else{
                        platform = '';
                    }
                }else{
                    if (this.getPlatformId > 0) {
                        platform = this.getPlatformId;
                    }else{
                        platform = '';
                    }
                }

                let action = this.typeStatusSelected;
                let result = this.typeResultsSelected;
                let code = this.codeSelected;
                let betCode = this.betCodeSelected;
                let idEvent = this.idEvent;
                let searchById = this.searchById;
                let searchByLogin = this.searchByLogin;
                let fromTime = "";
                let toTime = "";

                if(this.filterFromTime){
                    fromTime = new Date(this.filterFromTime).getTime();
                }
                if(this.filterToTime) {
                    toTime = new Date(this.filterToTime).getTime() + 86399000;
                }

                const url = this.urlRisk + 'logs/charts-ai';
                await axios.post(`${url}`, {
                    "platform_id" : platform,
                    "action" : action,
                    "result" : result,
                    "code" : code,
                    "betCode" : betCode,
                    "idEvent" : +idEvent,
                    "user_id":searchById, //chi accetta
                    "login":searchByLogin,
                    "fromTime":fromTime,
                    "toTime":toTime,
                    "page":this.pagination.page,
                    "perPage":this.pagination.itemsPerPage
                },{'headers': {'Authorization': `Bearer ${this.getToken}`}})
                    .then(async (res) => {
                        this.bets_total_ai = res.data.data.total;
                        this.accepted_total_ai = res.data.data.accepted;
                        this.rejected_total_ai = res.data.data.rejected;
                        this.win_ai = res.data.data.win;
                        this.win_amount_ai = res.data.data.win_amount.toFixed(0);
                        this.lose_ai = res.data.data.lose;
                        this.win_percentage_ai = res.data.data.win_percentage;
                        this.lose_percentage_ai = res.data.data.lose_percentage;
                        this.accept_percentage_ai = res.data.data.accept_percentage;
                        this.reject_percentage_ai = res.data.data.reject_percentage;
                        this.net_ai =  (res.data.data.lose_amount!= null) ? res.data.data.lose_amount.toFixed(0) : 0;
                        this.loaded_chart_ai = true;
                        this.loader_ai = false;

                    }).catch((e)=>{
                        console.log(e)
                    })

            },
            async getLogs(charts){
                    if(charts === 'yes'){
                        this.pagination.page = 1
                    }
                    let platform;
                    if(this.getPlatformId === 0){
                        if (this.platformSelected > 0) {
                            platform = this.platformSelected;
                        }else{
                            platform = '';
                        }
                    }else{
                        if (this.getPlatformId > 0) {
                            platform = this.getPlatformId;
                        }else{
                            platform = '';
                        }
                    }

                    let action = this.typeStatusSelected;
                    let result = this.typeResultsSelected;
                    let code = this.codeSelected;
                    let betCode = this.betCodeSelected;
                    let idEvent = this.idEvent;
                    let searchById = this.searchById;
                    let searchByLogin = this.searchByLogin;
                    let fromTime = "";
                    let toTime = "";
                        if(this.filterFromTime){
                            fromTime = new Date(this.filterFromTime).getTime();
                        }else{
                            this.$swal.fire(this.$t('fillAllRequiredFields'), this.$t('generic.from')+" "+this.$t('bets.date'), 'error')
                            return;
                        }
                        if(this.filterToTime) {
                            toTime = new Date(this.filterToTime).getTime() + 86399000;
                        }else{
                            this.$swal.fire(this.$t('fillAllRequiredFields'), this.$t('generic.to')+" "+this.$t('bets.date'), 'error')
                            return;
                        }
                        //fake commmit
                        const url = this.urlRisk + 'logs/new-logs';
                        await axios.post(`${url}`, {
                            "platform_id" : platform,
                            "action" : action,
                            "result" : result,
                            "code" : code,
                            "betCode" : betCode,
                            "idEvent" : +idEvent,
                            "user_id":searchById, //chi accetta
                            "login":searchByLogin,
                            "fromTime":fromTime,
                            "toTime":toTime,
                            "page":this.pagination.page,
                            "perPage":this.pagination.itemsPerPage
                        },{'headers': {'Authorization': `Bearer ${this.getToken}`}})
                            .then(async (res) => {
                                const documents = res.data.data.documents;
                                this.pagination.counts = res.data.data.total;
                                this.tableItems = documents;

                                this.currency = this.tableItems[0] ? this.tableItems[0].currencySymbol : '';

                                if(this.getPlatformId != null && this.getPlatformId > 0 && charts === 'yes'){
                                    this.getChart();
                                    this.getChartAi();
                                }
                            }).catch((e)=>{
                                console.log(e)
                            })

                },
            openMessageDialog(id,code) {
                this.details = [];
                this.dialogNote = true;
                this.modalCode = code;
                this.platformIdTargetNote = this.getPlatformId;
                this.moreDetail(id);
            },
            async betDetails(code){
                let url ='https://rockybet.srp.tools/bet/detail';
                // let url = this.urlBet +'bet/bet-detail';

                return await axios.post(`${url}`,
                    {
                        'code':code
                    },
                    {
                        'headers': {
                            'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAiLCJlbWFpbCI6Im1haWwiLCJuYW1lIjoibmFtZSIsIndlYnNpdGUiOiJ1c2VyLndlYnNpdGUiLCJwbGF0Zm9ybV9pZCI6InBsYXRmb3JtX2lkIiwibG9naW4iOiJ1c2VyLmxvZ2luIiwic2NvcGUiOiJib29raWUiLCJ0eXBlIjoidXNlci50eXBlIiwiY2FzaGllciI6InVzZXIuY2FzaGllciIsInBlcm1pc3Npb25zIjpbIiMiXSwiaWF0IjoxNjg3ODYzMDI4LCJleHAiOjIxMTk4NjMwMjh9.GSpAwEnU8HX9XAo0NzI0BFfSVdAnB8JqSYnDNg1Y75s`,
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((res) => {
                        return res.data.data;
                    }).catch((error) => {
                        console.log(error);
                    });
            },
            async moreDetail(code){
                this.betDetail = [];
                this.betContext = '';
                this.old_value = '';
                this.new_value = '';
                        const url = this.urlRisk + 'logs/detail';
                        await axios.post(`${url}`, {
                            "id" : code,
                        },{'headers': {'Authorization': `Bearer ${this.getToken}`}})
                            .then(async (res) => {
                                this.details = res.data.data;
                                if(res.data.data.old_value != ""){
                                    this.oldValueDecode(res.data.data.old_value);
                                }
                                if(res.data.data.new_value != ""){
                                    this.newValueDecode(res.data.data.new_value);
                                }
                                this.betDetail = await this.betDetails(res.data.data.code)

                                // this.showShortCode = await this.skinShortCode(this.betDetail.platform_id)

                                if(this.betDetail != null && this.betDetail.bet_context){
                                    this.betContext = this.betDetail.bet_context;
                                    this.betResult = this.betDetail.result;
                                    this.amountReal = this.betDetail.amount_real;
                                    this.agencyLogin = this.betDetail.agencyLogin;

                                    if(this.betDetail.childBet){
                                        this.newCode = this.betDetail.childBet
                                        this.newBetCode = this.betDetail.childBetCode
                                    }else{
                                        this.newCode = this.betDetail.code
                                        this.newBetCode = this.betDetail.betCode
                                    }

                                }else if(res.data.data.old_value.bet_context){
                                    this.betContext = res.data.data.old_value.bet_context;
                                }else{
                                    this.betContext = "Bet Context";
                                }

                            }).catch((e)=>{
                                console.log(e)
                            })

                },
            async skinShortCode(platformId){
                var skin = await _.find(this.getSkins, {"platform_id": platformId});
                var cartRule = _.find(skin.rules, {"type": "cart", "scope": "cart", "source": "betconsole"})
                if(cartRule.detail.shortCode){
                    return true
                }else{
                    return false
                }
            },
            }
    }
</script>

<style scoped>

    .odd-event-description{
        margin-top: 6px;
        margin-bottom: 6px;
        height: 10px;
        display: block;
    }
    .row+.row {
        margin-top: 10px;
    }
    fieldset {
        height: 50px;
    }
    input{
        padding: 0 !important;
    }
    .pb-0.ptb {
        max-height: 50px;
    }
    .bet-info fieldset {
        height: 40px;
        padding: 0;
    }
    .bet-info .pt-4.pt-0 {
        max-height: 60px;
        padding-top: 15px !important;
    }
    .content-bet{
        display: block;
        margin: 0 auto;
    }
</style>
